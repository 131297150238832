.App {
  padding: 0px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: rgb(41,41,41);
}

.app-section{
  width: 100%;
  max-width: 600px;
}

*{
  /* outline: 3px solid red; */
}

.image-desc{
  font-size: 0.8rem;
  background-color: red;
}

/* Responsiveness */
@media only screen and (min-width: 480px) {
  .App {
    padding: 0px 30px;
  }
}

@media only screen and (min-width: 768px) {
  .App {
    padding: 0px 60px;

  }
}

/* Antd Style Overwrites */
.ant-spin-fullscreen {
  background-color: rgba(0, 0, 0, 0.7);
}

.ant-spin-dot-item  {
  background-color: orange  !important;
}

.ant-spin-text{
  color: orange   !important;
}
